<template>
    <r-e-dialog title="开锁记录" :visible.sync="dialogVisible" top="5vh" width="700px" :show-footer="false">
        <div>
            <el-select size="small" v-model="formSearch.type" placeholder="请选择" v-if="iotTag===0">
                <el-option label="全部" value=""/>
                <el-option label="密码开门" value="EVENT_PWD_ACTION"/>
                <el-option label="钥匙开门" value="EVENT_KEY_ACTION"/>
                <el-option label="临时密码开门" value="EVENT_TOKEN_ACTION"/>
                <el-option label="内把手开门" value="EVENT_INNER_HANDLE_ACTION"/>
                <el-option label="关门" value="EVENT_CLOSE"/>
            </el-select>
            <el-date-picker v-model="dateArr" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
        </div>
        <r-e-table ref="communityTableRef" :columns="tableColumnUnlockRecord" :dataRequest="getFamilyList"
                   :query="formSearch" :height="400" :pretreat="pretreat">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </r-e-dialog>
</template>

<script>
import {tableColumnUnlockRecord} from "@/views/rental-management/synthetical-management/data";
import {openLocksLog} from "@/api/door-management"

export default {
    name: "dialog-open-lock-log",
    data() {
        return {
            dialogVisible: false,
            dataList: [],
            tableColumnUnlockRecord,
            uuid: '',
            dateArr: [],
            formSearch: {
                type: ''
            },
            iotTag: null,
        }
    },
    props: {},
    components: {},
    methods: {
        openDialog(data, iotTag) {
            this.iotTag = Number(iotTag);
            this.uuid = data;
            this.dialogVisible = true;
        },
        getFamilyList(params) {
            let {uuid} = this;
            if (this.dateArr && this.dateArr.length !== 0) {
                let [startDate, endDate] = this.dateArr;
                params.startDate = startDate;
                params.endDate = endDate;
            }
            return openLocksLog(uuid, params);
        },

        handleSearch() {
            this.$refs["communityTableRef"].getTableData(true);
        },

        pretreat(list) {
            if (this.iotTag === 0) {
                list = list.map(item => {
                    const {time, typeDesc} = item;
                    return {...item, time, typeDesc}
                });
            }
            if (this.iotTag === 1) {
                list = list.map(item => {
                    const {updateTime, logAlert: typeDesc} = item;
                    const time = updateTime * 1000;
                    return {...item, time, typeDesc}
                });
            }
            if (this.iotTag === 2) {
                list = list.map(item => {
                    const {operateTime, remark: typeDesc} = item;
                    const time = Number(operateTime);
                    return {...item, time, typeDesc}
                });
            }
            return list;
        }
    },
}
</script>

<style scoped>

</style>